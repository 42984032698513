import React from "react"
import { SocialIcon } from "react-social-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Kontakt = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className="mt-48 container mx-auto px-2">
        <div className="grid lg:grid-cols-6 md:grid-cols-3 gap-12">
          <div>
            <h1 className="md:text-8xl text-6xl font-bold break-words">
              kontakt
            </h1>
          </div>
          <div>
            <p className="font-bold text-2xl">
              preisanfragen, auftragsarbeiten, sonstiges
            </p>
            <a
              href="mailt:felicia.rensner@hotmail.de"
              className="inline-block mt-8 border border-solid border-black py-2 px-6"
            >
              felicia.rensner@hotmail.de
            </a>
            <br />
            <SocialIcon
              className="mt-8 -ml-6"
              style={{ height: 100, width: 100 }}
              bgColor="transparent"
              fgColor="black"
              url="https://www.instagram.com/r3nsner/"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Kontakt
